import React, {useState, useEffect} from 'react'
import SEO from '../components/App/SEO'
import Layout from '../components/App/Layout'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Banner from "../components/Index/Banner"
import { graphql } from 'gatsby'
import bannerImg1 from '../assets/images/download/download-desktop.webp'
import bannerImg2 from '../assets/images/download/download-mobile1.webp'
import bannerImg3 from '../assets/images/download/download-mobile3.webp'
import {isMobile, isAndroid} from 'react-device-detect'
import ClickAnalytics from '../components/ComeBack/ClickAnalytics'
import DownloadPageEvents from '../components/Analytics/DownloadPageEvents'

const Download = ({data}) => {
    const [bannerImage, setBannerImage] = useState('')
    const [bannerTitle, setBannerTitle] = useState('')

    useEffect(() => {
      if(isMobile) {
        if(isAndroid){
          setBannerImage(bannerImg3)
          setBannerTitle('MOBILE')
        } else {
          setBannerImage(bannerImg2)
          setBannerTitle('MOBILE')
        }          
      }else {
          setBannerImage(bannerImg1)
          setBannerTitle('DESKTOP')
      }
    }, [])

    const banner = {
      title: <h1>BLUEMAIL <span>{bannerTitle}</span></h1>,
      subtitle: 'No Ads. 100% free. All Around Secure',
      image: bannerImage
    }

    return (
        <Layout env={data.site.siteMetadata.env}>
            <Navbar />
            <SEO 
                postTitle="Download | BlueMail App"
                postDescription="BlueMail is available for Android, iOS mobile devices and on Windows, Mac, & Linux. Download today"
                postImage="/img/OG/og_image-Download.png"
                postURL="download"
                postSEO
            />
            <Banner banner={banner} />
            <Footer />
        </Layout>
    );
}

export default Download;

export const query = graphql`
query DownloadPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`